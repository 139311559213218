import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import Aux from '../../../hoc/Aux/Aux';
import ContentHeader from '../../../components/Content/ContentHeader/Contentheader';
import Spinner from '../../../components/UI/Spinner/Spinner';
import './AircraftDetail.css';
import OtaLogsByAircraft from '../../../components/Content/AircraftContent/OtaLogsByAircraft';
import PiwikLogsByAircraft from '../../../components/Content/AircraftContent/PiwikLogsByAircraft';
import HeartBeatsByAircraft from '../../../components/Content/AircraftContent/HeartBeatsByAircraft';
import AircraftSummaryList from './AircraftSummaryList';
import AlertByAircraft from "../../../components/Content/AircraftContent/AlertByAircraft";
import Settings from "../../../components/Content/AircraftContent/Settings/Settings";
import FlightsAircraft from "../../../components/Content/AircraftContent/FlightsAircraft";
import UsbMediaSyncLog from '../../../components/Content/AircraftContent/UsbMediaSyncLog';
import UgoLogArchiveByAircraft from "../../../components/Content/AircraftContent/UgoLogArchiveByAircraft";
import MessageInfo from "../../../components/Messages/MessageInfo";
import {grantAnyPermissionOf, grantPermission} from "../../../hoc/RoleService/RoleService";
import CompanyService from "../../../hoc/CompanyService/CompanyService";
import MediaSyncsByAircraft from "../../../components/Content/AircraftContent/MediaSyncsByAircraft";
import RemoteConnexionByAircraft from "../../../components/Content/AircraftContent/RemoteConnexionByAircraft";
import ConfigDeployerByAircraft from "../../../components/Content/AircraftContent/ConfigDeployerByAircraft";
import ConfigDeployerBatchByAircraft from "../../../components/Content/AircraftContent/ConfigDeployerBatchByAircraft";
import KnowledgeBaseDocumentByAircraft
    from "../../../components/Content/AircraftContent/KnowledgeBaseDocumentByAircraft";
import AutoConfigByAircraft from "../../../components/Content/AircraftContent/AutoConfigByAircraft";
import PartitionList from '../../../components/Partition/PartitionList';

const INTERVAL_UPDATE_CONTENT = 120000

class AircraftDetail extends Component {
    constructor(props) {
        super(props);
        this.companyService = new CompanyService()
        this.ErpUrl = process.env.REACT_APP_ERP_HOST;
        this.Auth = this.props.auth;
        this.statusHandler = this.props.statusHandler;
        this.state = {
            icao: '',
            search: '',
            uuid: '',
            currentHash: '#alerts',
            transportUnit: {
                name: '',
                operatorCompany: {
                    icao: ''
                }
            },
            hash: '',
            loading: true,
            error: {
                status: false,
                message: ''
            },
            status: {
                color: 'success',
                icon: 'ok'
            }
        };

        this.tabList = [
            {
                hash: '#alerts',
                title: 'Alerts',
                roles: ['aircraft_alerts_current', 'aircraft_alerts_closed']
            },
            {
                hash: '#sync',
                title: 'OTA Contents Sync'
            },
            {
                hash: '#usbsync',
                title: 'USB Contents Sync'
            },
            {
                hash: '#sync4',
                title: 'Synchronizations (UGO 4.0+)'
            },
            {
                hash: '#logs',
                title: 'Logs (2.x)'
            },
            {
                hash: '#ugolog',
                title: 'Logs (3.0+)'
            },
            {
                hash: '#heartbeat',
                title: 'HeartBeat'
            },
            {
                hash: '#flights',
                title: 'Flights',
                roles: ['aircraft_flights_closed']
            },
            {
                hash: '#rmc',
                title: 'RMC',
                roles: ['rmc']
            },
            {
                hash: '#config_deployer',
                title: 'Config Deployer',
                roles: ['configuration_deployer']
            },
            {
                hash: '#self-configuration',
                title: 'Self-configuration',
                roles: ['self_configuration']
            },
            {
                hash: '#knowledge_base_document',
                title: 'Knowledge Base',
                roles: ['role_public_kb', 'role_private_kb']
            },
            {
                hash: '#drives',
                title: 'Internal Drives'
            },
        ];
    }

    hasAccessToAnyOf = (roles) => {
        if (roles === null) {
            return true;
        }

        return roles.some(role => grantPermission(role, this.props.auth));
    }

    grantedTabList = () => {
        return this.tabList.filter(tab => this.hasAccessToAnyOf(tab.roles ?? null));
    }

    componentDidMount() {
        const { icao } = this.props.match.params;
        this.setState({ 'icao': icao })

        this.companyService.redirectUserIfNotDisplayUser(icao, this.props.auth)

        document.body.classList.value = 'skin-blue sidebar-mini'
        const search = this.props.match.params.uuid + this.props.location.search + this.props.location.hash
        this.setState({ search })

        const currentHash = this.props.location.hash ?? this.state.currentHash;

        if (currentHash) {
            const grantedTabList = this.grantedTabList();
            const validHash = grantedTabList.some(tab => tab.hash === currentHash);

            if (!validHash) {
                this.setState({ currentHash: grantedTabList[0].hash })
            } else {
                this.setState({ currentHash: currentHash })
            }
        }

        this.getContent(search)

        setInterval(() => {
            this.refreshHandler(false);
        }, INTERVAL_UPDATE_CONTENT);
    }

    getContent = (search = null) => {
        const uuid = this.props.match.params.uuid;
        let uri = search ? 'transport_units/' + search : 'transport_units/' + uuid

        this.Auth.fetch(uri)
            .then(res => {
                if (res.code && res.code === 401) {
                    this.props.history.push({
                        pathname: '/login',
                        state: { previousUrl: this.props.location.pathname }
                    });
                }
                let error = '';
                if (res['hydra:description']
                    && (res['hydra:description'] === 'Not Found'
                        || res['hydra:description'] === 'Access Denied.')
                ) {
                    error = {
                        status: true,
                        message: res['hydra:description']
                    }
                }
                const status = this.statusHandler.getFullStatus(res.status);

                this.getHash(res.remoteDeployerBranch);
                this.setState({ uuid, transportUnit: res, loading: false, status: status, error: error });
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: {
                        status: true,
                        message: e.toString()
                    }
                });
            });
    };

    erpButtonHandler = () => {
        window.open(
            this.ErpUrl + 'redirect/transportunit/' + this.state.transportUnit.registration,
            '_blank'
        )
    }

    flightRadarHandler = () => {
        window.open(
            'https://www.flightradar24.com/data/aircraft/' + this.state.transportUnit.registration,
            '_blank'
        )
    }

    statusClickedHandler = () => {
        const uuid = this.state.transportUnit.uuid
        this.setState({ currentHash: '#alerts' })

        this.props.history.push({
            pathname: '/' + this.state.icao + '/aircraft/' + uuid,
            hash: this.state.currentHash
        })
    }

    refreshHandler = (withLoading = true) => {
        this.setState({
            loading: withLoading,
            error: {
                status: false,
                message: ''
            }
        });
        this.getContent();
    }

    tabClickedHandler = (e) => {
        const currentHash = e.target.getAttribute('href')
        this.setState({ currentHash })
        this.props.history.push({
            pathname: '/' + this.state.icao + '/aircraft/' + this.state.uuid,
            hash: currentHash,
            search: '?page=1',
            state: { previousUrl: this.props.location.pathname }
        })
    }

    tabIsActive = (hash) => {
        return hash === this.state.currentHash ? 'chart tab-pane active' : 'chart tab-pane'
    }

    getHash = (remoteDeployerBranch) => {
        this.Auth.fetch('gitlab_ugo_remote_deployer_last_commits?exists[commitHash]=true&itemsPerPage=1&order[createdAt]=desc&branch=' + remoteDeployerBranch)
            .then(res => {
                if (res.code && res.code === 401) {
                    this.props.history.push({
                        pathname: '/login',
                        state: { previousUrl: this.props.location.pathname }
                    });
                }

                if (res['hydra:member'].length > 0) {
                    this.setState({ hash: res['hydra:member'][0].commitHash.substring(0, 8) ?? 'N/A' });
                } else {
                    this.setState({ hash: 'N/A' });
                }
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: {
                        status: true,
                        message: e.toString()
                    }
                });
            });
    }

    getRemoteStatus = (hash, transportUnit) => {
        if (transportUnit.lastRemoteDeployerHash === null) {
            return (<span className='label label-warning'>To be applied</span>);
        }

        if (hash === transportUnit.lastRemoteDeployerHash.substring(0, 8)) {
            return (<span className='label label-success'>Up to date</span>);
        }

        if (hash === 'N/A') {
            return (<span className='label label-success'>No config available</span>);
        }

        if (hash !== transportUnit.lastRemoteDeployerHash.substring(0, 8)) {
            return (<span className='label label-warning'>To be applied</span>);
        }
    }

    render() {
        let aircraftContent = (
            <div className="box">
                <Spinner />
            </div>
        )

        let list = this.grantedTabList()
        let tabList = []

        list.map((value, key) => {
            if (this.state.transportUnit.synchronizationType === 'sync_v1') {
                if (value.hash !== "#sync4") {
                    tabList.push(value)
                }
            } else if (this.state.transportUnit.synchronizationType === 'sync_v2_legacy' || this.state.transportUnit.synchronizationType === 'sync_v2_download') {
                if (value.hash !== '#sync' && value.hash !== '#usbsync') {
                    tabList.push(value)
                }
            } else {
                // if synchronizationType is not defined, we display all tabs except tabs sync
                if (value.hash !== '#sync' && value.hash !== '#usbsync' && value.hash !== '#sync4') {
                    tabList.push(value)
                }

            }
            return value;
        });

        tabList =
            tabList.map(tab => (
                <li key={tab.hash} className={tab.hash === this.state.currentHash ? 'active' : null}>
                    <a href={tab.hash} data-toggle="tab">{tab.title} </a>
                </li>
            ))

        tabList = (
            <ul onClick={this.tabClickedHandler} className="nav nav-tabs pull-left">
                {tabList}
            </ul>
        )

        const notAvailableFeature = (<div className="pad margin no-print">
            <div className="callout callout-info" style={{ marginBottom: '0!important' }}>
                <h4><i className="fa fa-info" /> Note</h4>
                This feature is not enabled in ERP, so alerting is disabled for this part.
            </div>
        </div>)

        const notAvailableFlightTracked = (<div className="pad margin no-print">
            <div className="callout callout-info" style={{ marginBottom: '0!important' }}>
                <h4><i className="fa fa-info" /> Note</h4>
                This feature is not enabled in ERP, flights are not tracked right now. So this list is not updated.
            </div>
        </div>)

        const notAvailableFeatureUsb = (<div className="pad margin no-print">
            <div className="callout callout-info" style={{ marginBottom: '0!important' }}>
                <h4><i className="fa fa-info" /> Note</h4>
                This history is built from Part Number received in heartbeats. As heartbeat feature is not enabled for
                this aircraft, this history cannot be updated.
            </div>
        </div>)

        const availableFeatureUsb = (<div>
            <div className="pad margin no-print">
                <p className="text-muted">
                    <i className="fa fa-info" aria-hidden="true"></i> This feature is available since UGO 2.7. Dates are
                    estimated from received heartbeats.</p>
            </div>
        </div>)

        const availableFeatureUgoLogArchive = (<div>
            <div className="pad margin no-print">
                <p className="text-muted">
                    <i className="fa fa-info" aria-hidden="true" /> This feature is available since UGO 3.0. </p>
            </div>
        </div>)

        const availableFeatureUGOFourZero = (<div>
            <div className="pad margin no-print">
                <p className="text-muted">
                    <i className="fa fa-info" aria-hidden="true" /> This feature is available since UGO 4.0. </p>
            </div>
        </div>)

        let usbSyncLogsContent = <></>
        let otaLogsContent = <></>
        let mediaSyncsContent = <></>

        if (this.state.transportUnit.synchronizationType === 'sync_v1') {
            usbSyncLogsContent =
                <div>
                    {this.state.transportUnit.isHeartBeatSent ? availableFeatureUsb : notAvailableFeatureUsb}
                    <UsbMediaSyncLog
                        clickable={false}
                        transportUnitUuid={this.state.transportUnit.uuid}
                        search={this.state.currentHash === '#usbsync' ? this.state.search : null}
                        icao={this.state.icao}
                        auth={this.props.auth} />
                </div>

            otaLogsContent =
                <div>
                    {this.state.transportUnit.isOtaSyncActivated ? '' : notAvailableFeature}
                    <OtaLogsByAircraft
                        clickable={false}
                        transportUnitUuid={this.state.transportUnit.uuid}
                        search={this.state.currentHash === '#sync' ? this.state.search : null}
                        auth={this.props.auth} />
                </div>
        }

        if (this.state.transportUnit.synchronizationType === 'sync_v2_legacy' || this.state.transportUnit.synchronizationType === 'sync_v2_download') {
            mediaSyncsContent =
                <div>
                    <MediaSyncsByAircraft
                        clickable={grantPermission('media_sync', this.props.auth)}
                        transportUnitUuid={this.state.transportUnit.uuid}
                        search={this.state.currentHash === '#sync4' ? this.state.search : null}
                        icao={this.state.icao}
                        auth={this.props.auth} />
                </div>
        }

        const ugoLogArchiveContent =
            <div>
                {availableFeatureUgoLogArchive}
                <UgoLogArchiveByAircraft
                    clickable={grantPermission('ugo_log', this.props.auth)}
                    transportUnitUuid={this.state.transportUnit.uuid}
                    search={this.state.currentHash === '#ugolog' ? this.state.search : null}
                    icao={this.state.icao}
                    auth={this.props.auth} /></div>

        const piwikLogsContent =
            <div>
                {this.state.transportUnit.isLogsSent ? '' : notAvailableFeature}
                <PiwikLogsByAircraft
                    clickable={false}
                    transportUnitUuid={this.state.transportUnit.uuid}
                    search={this.state.currentHash === '#logs' ? this.state.search : null}
                    auth={this.props.auth} /></div>

        const heartBeatsContent =
            <div>
                {this.state.transportUnit.isHeartBeatSent ? '' : notAvailableFeature}
                <HeartBeatsByAircraft
                    clickable={grantPermission('heartbeat', this.props.auth)}
                    transportUnitUuid={this.state.transportUnit.uuid}
                    search={this.state.currentHash === '#heartbeat' ? this.state.search : null}
                    icao={this.state.icao}
                    auth={this.props.auth} /></div>

        const knowledgeBaseDocumentContent =
            <div>
                <KnowledgeBaseDocumentByAircraft
                    clickable={grantAnyPermissionOf(['role_public_kb', 'role_private_kb'], this.props.auth)}
                    transportUnitUuid={this.state.transportUnit.uuid}
                    search={this.state.currentHash === '#knowledge_base_document' ? this.state.search : null}
                    icao={this.state.icao}
                    auth={this.props.auth}/>
            </div>

        const flightsContent =
            <div>
                {this.state.transportUnit.areFlightTracked ? '' : notAvailableFlightTracked}
                <FlightsAircraft
                    clickable={grantPermission('flight', this.props.auth)}
                    transportUnitUuid={this.state.transportUnit.uuid}
                    search={this.state.currentHash === '#flights' ? this.state.search : null}
                    dataTransportUnit={this.state.transportUnit}
                    icao={this.state.icao}
                    auth={this.props.auth}
                    statusHandler={this.statusHandler}
                />
            </div>

        const remoteConnexionContent =
            <div>
                <RemoteConnexionByAircraft
                    clickable={grantPermission('rmc', this.props.auth)}
                    transportUnitUuid={this.state.transportUnit.uuid}
                    search={this.state.currentHash === '#remote' ? this.state.search : null}
                    icao={this.state.icao}
                    auth={this.props.auth}
                />
            </div>

        let configurationTableContent = <>
            <ConfigDeployerByAircraft
                clickable={false}
                transportUnitUuid={this.state.transportUnit.uuid}
                search={this.state.currentHash === '#config_deployer' ? this.state.search : null}
                icao={this.state.icao}
                auth={this.props.auth}
                tableTitle="Configuration deployed"
            />
            {grantPermission('configuration_deployer_history', this.props.auth) ? <>
                <ConfigDeployerBatchByAircraft
                    clickable={false}
                    transportUnitUuid={this.state.transportUnit.uuid}
                    search={this.state.currentHash === '#config_deployer' ? this.state.search : null}
                    icao={this.state.icao}
                    auth={this.props.auth}
                    tableTitle="All session history"
                />
            </> : ''}
        </>

        const configurationDeployerContent =
            <>
                {availableFeatureUGOFourZero}
                <div className="pad margin no-print">
                    <div className="row">
                        <div className="col-md-6">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>Configuration deployer activated</td>
                                        <td>
                                            {this.state.transportUnit.autoRemoteDeployer ?
                                                <span className='label label-success'>Yes</span>
                                                : <span className='label label-default'>No</span>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Configuration deployer branch</td>
                                        <td>{this.state.transportUnit.remoteDeployerBranch ? this.state.transportUnit.remoteDeployerBranch : 'main'}</td>
                                    </tr>
                                    <tr>
                                        <td>Config Deployer User</td>
                                        <td>{this.state.transportUnit.user ? this.state.transportUnit.user : 'ugo'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {this.state.transportUnit.autoRemoteDeployer ? <>
                            <div className="col-md-6">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Latest reference (hash) available</td>
                                            <td>
                                                {this.state.hash}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td>{this.getRemoteStatus(this.state.hash, this.state.transportUnit)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </> : ''}
                    </div>
                </div>
                {this.state.transportUnit.autoRemoteDeployer ? configurationTableContent : ''}
            </>

        if (!this.state.loading) {
            if (this.state.transportUnit.operatorCompany.icao.toLowerCase() !== this.state.icao && !this.companyService.isDisplayIcao(this.state.icao)) {
                window.location.replace('/403')
            } else {
                aircraftContent = (
                    <Aux>
                        <AircraftSummaryList
                            statusClicked={this.statusClickedHandler}
                            flightRadarClicked={this.flightRadarHandler}
                            erpClicked={this.erpButtonHandler}
                            status={this.state.status}
                            transportUnit={this.state.transportUnit}
                            auth={this.props.auth}
                            icao={this.state.icao}
                        />
                        <div className="box">
                            <div className="box-footer">
                                <div className="nav-tabs-custom">
                                    {tabList}
                                    <div className="tab-content no-padding">
                                        <div className={this.tabIsActive('#sync')} id="sync" style={{ position: 'relative' }}>
                                            {otaLogsContent}
                                        </div>
                                        <div className={this.tabIsActive('#usbsync')} id="usbsync"
                                            style={{ position: 'relative' }}>
                                            {usbSyncLogsContent}
                                        </div>
                                        <div className={this.tabIsActive('#logs')} id="logs" style={{ position: 'relative' }}>
                                            {piwikLogsContent}
                                        </div>
                                        <div className={this.tabIsActive('#ugolog')} id="ugolog"
                                            style={{ position: 'relative' }}>
                                            {ugoLogArchiveContent}
                                        </div>
                                        <div className={this.tabIsActive('#heartbeat')} id="heartbeat"
                                            style={{ position: 'relative' }}>
                                            {heartBeatsContent}
                                        </div>
                                        <div className={this.tabIsActive('#sync4')} id="sync4"
                                            style={{ position: 'relative' }}>
                                            {mediaSyncsContent}
                                        </div>
                                        {grantPermission('rmc', this.props.auth) ?
                                            <div className={this.tabIsActive('#rmc')} id="rmc"
                                                 style={{ position: 'relative' }}>
                                                {remoteConnexionContent}
                                            </div> : ""}

                                        {grantAnyPermissionOf(['role_public_kb', 'role_private_kb'], this.props.auth) ?
                                            <div className={this.tabIsActive('#knowledge_base_document')} id="knowledge_base_document"
                                                 style={{ position: 'relative' }}>
                                                {knowledgeBaseDocumentContent}
                                            </div> : ""}

                                        {grantPermission('configuration_deployer', this.props.auth) ?
                                            <div className={this.tabIsActive('#config_deployer')} id="config_deployer"
                                                style={{ position: 'relative' }}>
                                                {configurationDeployerContent}
                                            </div> : ""}

                                        {grantPermission('self_configuration', this.props.auth) ?
                                            <div className={this.tabIsActive('#self-configuration')} id="settings"
                                                style={{ position: 'relative' }}>
                                                <AutoConfigByAircraft
                                                    transportUnit={this.state.transportUnit}
                                                    clickable={false}
                                                    search={this.state.currentHash === '#self-configuration' ? this.state.search : null}
                                                    icao={this.state.icao}
                                                    auth={this.props.auth}
                                                    tableTitle="Self-configuration"
                                                />
                                            </div>: "" }

                                        <div className={this.tabIsActive('#alerts')} id="alerts"
                                            style={{ position: 'relative' }}>
                                            <Settings
                                                transportUnit={this.state.transportUnit} type="ALL"
                                                auth={this.props.auth}
                                            />
                                            {grantPermission('aircraft_alerts_current', this.props.auth) ?
                                                <AlertByAircraft
                                                    clickable={grantPermission('admin', this.props.auth)
                                                        || grantPermission('aircraft_alerts_ack', this.props.auth)}
                                                    statusHandler={this.statusHandler}
                                                    search={this.state.currentHash === '#alerts' ? this.state.search : null}
                                                    transportUnitUuid={this.state.transportUnit.uuid}
                                                    auth={this.props.auth}
                                                    closedAt={false}
                                                    tableTitle="Open alerts"
                                                    messageTitle="<p className='text-muted'><i className='fa fa-info' aria-hidden='true'></i> Everything is OK, no alert is opened currently!</p>"
                                                /> : ''}

                                            {grantPermission('aircraft_alerts_closed', this.props.auth) ?
                                                <AlertByAircraft
                                                    clickable={false}
                                                    statusHandler={this.statusHandler}
                                                    search={this.state.currentHash === '#alerts' ? this.state.search : null}
                                                    transportUnitUuid={this.state.transportUnit.uuid}
                                                    auth={this.props.auth}
                                                    closedAt={true}
                                                    tableTitle="Histories"
                                                    messageTitle="<p className='text-muted'><i className='fa fa-info' aria-hidden='true'></i> This aircraft does not have any closed alerts.</p>"
                                                /> : ''}
                                        </div>
                                        {grantPermission('aircraft_flights_closed', this.props.auth) ?
                                            <div className={this.tabIsActive('#flights')} id="flights"
                                                style={{ position: 'relative' }}>
                                                {flightsContent}
                                            </div> : ''}
                                        {this.state.transportUnit.partitions && (
                                            <div className={`${this.tabIsActive('#drives')} drives`} id="drives"
                                            style={{ position: 'relative' }}>
                                                <PartitionList partitions={this.state.transportUnit.partitions} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Aux>
                );
            }
        }

        let pageContent = (
            <Aux>
                {aircraftContent}
            </Aux>
        );

        if (this.state.error.status) {
            pageContent = <MessageInfo refreshHandler={this.refreshHandler} message={this.state.error.message} />
        }

        return (
            <Aux>
                <ContentHeader
                    refreshClicked={this.refreshHandler}
                    subMenu={{ name: 'Aircrafts', link: '/aircraft' }}
                    nameHeader='Aircraft'
                    smallHeader='Detail' />
                <section className="content">
                    {pageContent}
                </section>
            </Aux>
        );
    }
}

export default withRouter(AircraftDetail);
