import React from "react";

const getColorFromPercentage = (percentage) => {
    if (percentage >= 90) return "red";
    if (percentage >= 80) return "yellow";
    return "green";
};

const Partition = ({ label, percentage }) => {
    const safePercentage = Math.min(100, Math.max(0, Number(percentage) || 0)); // Ensures a valid range between 0 and 100
    const color = getColorFromPercentage(safePercentage);

    return (
        <div className="progress-group">
            <span className="progress-text">{label}</span>
            <span className="progress-number">{safePercentage}%</span>

            <div className="progress sm">
                <div
                    className={`progress-bar progress-bar-${color}`}
                    style={{ width: `${safePercentage}%` }}
                />
            </div>
        </div>
    );
};

export default Partition;
