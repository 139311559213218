import React from "react";
import Partition from "./Partition";

class PartitionList extends React.Component {
    constructor(props) {
        super(props);

        let partitions = [];
        try {
            const partitionsJson = JSON.parse(props.partitions);
            if (Array.isArray(partitionsJson)) {
                partitions = partitionsJson;
            } else {
                console.debug("Invalid partitions format: Expected an array.");
            }
        } catch (error) {
            console.debug("Error parsing partitions JSON:", error);
        }

        this.state = { partitions };
    }

    calcPercentage(freeSpace, totalSpace, fraction = 0) {
        return totalSpace > 0
            ? ((100 * (totalSpace - freeSpace)) / totalSpace).toFixed(fraction)
            : 0;
    }

    render() {
        if (this.state.partitions.length === 0) {
            return <div>No results found.</div>;
        }

        return (
            <div className="partition-list">
                {this.state.partitions.map((partition, index) => (
                    <Partition
                        key={index}
                        label={partition.name + " - " + partition.mount}
                        percentage={this.calcPercentage(
                            partition.free_space_gigabytes,
                            partition.total_size_gigabytes
                        )}
                    />
                ))}
            </div>
        );
    }
}

export default PartitionList;
